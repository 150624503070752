import CertCard from "./CertCard.js"

import hr from "../assets/curve-hr.svg"
import chatapp from "../assets/certs/chatapp.png"
import wallpaperapp from "../assets/certs/wallpaper_app.png"
import gestion_ecoleapp from "../assets/certs/gestion_ecole.png"
import objectTracking from "../assets/certs/object_tracking.png"
// import 

export default function Certs(){
    function importAll(r) {
        return r.keys().map(r);
    }
      
    const chatapp_interfaces = importAll(require.context('../assets/certs/chatapp/', false, /\.(png|jpe?g|svg)$/));
    const wallpaper_app = importAll(require.context('../assets/certs/wallpaper_app/', false, /\.(png|jpe?g|svg)$/));
    const gestion_ecole = importAll(require.context('../assets/certs/gestion_ecole/', false, /\.(png|jpe?g|svg)$/));
    const object_tracking_interfaces = importAll(require.context('../assets/certs/object_Tracking/', false, /\.(png|jpe?g|svg)$/));

    const githubLinks = [
        "https://github.com/MouncifHasni/WallpaperApp",
        "https://github.com/MouncifHasni/ChatApp",
        "https://github.com/MouncifHasni/gestion_ecole",
        "#"
    ]

    const desc = [
        "C'est une application android qui permet de personnalisez votre téléphone selon vos goûts et vos envies grâce à une bibliothèque d'images riches et variées. L'application vous permet de trouver des fonds d'écran en haute résolution pour votre écran d'accueil."
        +"Vous pouvez facilement parcourir plusieurs fonds d'écran triés par catégories et les télécharger facilement."
        +" En outre, l'application vous permet également de partager vos fonds d'écran préférés avec vos amis via les réseaux sociaux et les applications de messagerie instantanée."
        +" Créer avec : JAVA - Firebase",

        "Cette application offre aux utilisateurs une interface conviviale, une navigation facile et des fonctionnalités" 
        +"avancées telles que la création de groupes de discussion, communiquer en temps réel à l'aide de messages texte et de photos, la possibilité d'envoyer des demandes d'amitiés et la personnalisation du profil. Créer avec : JAVA - Firebase",

        "Application de gestion d'école avec base de données oracle faite sous forme de mini projet à l'école,"+ 
       "l'application permet de faire la Gestion des Filières/ Gestion des Modules de Filière/ Gestion des Éléments de Module / Gestion des Étudiants / Gestion des Enseignants"+
       " avec Fonctionnalités d'Ajout, Modification, Suppression et Filtrage. "+
       "Créer avec : PHP - HTML - CSS - bootsrap - ORACLE",

       "Conception et déploiement d’un algorithme de deep learning pour suivre automatiquement"
       +"les objets dans des images hyperspectrales. Optimisation d'un pipeline existant, test de"
       +"frameworks web pour le déploiement de l'IA et exploration des méthodes pour améliorer lesperformances du modèle."
    ];

      
    return (
        <div id="projets" className="mt-4 text-white">
            <h1 className="text-2xl font-bold">Derniers Projets</h1>

            {/* <div className="flex flex-col md:flex-row flex-wrap mt-4 gap-5"> */}
            <div className="grid grid-cols-1 md:grid-cols-3 justify-center mt-4 gap-5">
                <CertCard name="Chat app" img={chatapp} desc = {desc[1]}  interfaces ={chatapp_interfaces} github={githubLinks[1]} />
                <CertCard name="Wallpaper app" img={wallpaperapp} desc = {desc[0]} interfaces ={wallpaper_app} github={githubLinks[0]} />
                <CertCard name="Gestion d'école app" img={gestion_ecoleapp} desc = {desc[2]} interfaces ={gestion_ecole} github={githubLinks[2]} />
                <CertCard name="SUIVI D'OBJETS" img={objectTracking} desc = {desc[3]} interfaces ={object_tracking_interfaces} github={githubLinks[3]} />

            </div>
            <img src={hr} className="w-full mt-8 md:h-2" alt="hr" />
        </div>
    )
}
